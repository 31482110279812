import React, {useEffect, useState} from 'react'
import {MyProfileTabs} from '../../../../../app/pages/myProfile/MyProfile'
import {KTIcon} from '../../../../helpers'

const EngageWidgetMyProfile = ({tab, setTab, data}) => {
  const [totalsms, setTotalsms] = useState(0)
  const [usedsms, setUsedsms] = useState(0)

  useEffect(() => {
    let tempTotalsms = 0
    let tempUsedsms = 0

    data?.user_subscriptions?.forEach((pack) => {
      tempTotalsms += pack?.sms_count
      tempUsedsms += pack?.used_sms
    })

    setTotalsms(tempTotalsms)
    setUsedsms(tempUsedsms)
  }, [data])

  return (
    <div className='card mb-5 mb-xl-10'>
      <div className='card-body pt-9 pb-0'>
        <div className='d-flex flex-wrap flex-sm-nowrap mb-3'>
          <div className='me-7 mb-4'>
            <div
              className='d-flex align-items-center justify-content-center symbol symbol-100px symbol-lg-160px symbol-fixed position-relative'
              style={{height: '100%', width: '150px', backgroundColor: '#E8912D', fontSize: '60px'}}
            >
              {data?.name?.substr(0, 1)?.toUpperCase()}
            </div>
          </div>
          <div className='flex-grow-1'>
            <div className='d-flex justify-content-between align-items-start flex-wrap mb-2'>
              <div className='d-flex flex-column'>
                <div className='d-flex align-items-center mb-2'>
                  <a
                    href='javascript:void(0)'
                    className='text-gray-900 text-hover-primary fs-2 fw-bold me-1'
                  >
                    {data?.name}
                  </a>
                  <a href='javascript:void(0)'>
                    <KTIcon iconName='verify' className='text-primary fs-2x' />
                  </a>
                </div>
                <div className='d-flex flex-wrap fw-semibold fs-6 mb-2 pe-2'>
                  <a
                    href='javascript:void(0)'
                    className='d-flex align-items-center text-gray-400 text-hover-primary me-5 mb-2'
                  >
                    <span className='svg-icon svg-icon-4 me-1'>
                      <svg
                        width={18}
                        height={18}
                        viewBox='0 0 18 18'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          opacity='0.3'
                          d='M16.5 9C16.5 13.125 13.125 16.5 9 16.5C4.875 16.5 1.5 13.125 1.5 9C1.5 4.875 4.875 1.5 9 1.5C13.125 1.5 16.5 4.875 16.5 9Z'
                          fill='currentColor'
                        />
                        <path
                          d='M9 16.5C10.95 16.5 12.75 15.75 14.025 14.55C13.425 12.675 11.4 11.25 9 11.25C6.6 11.25 4.57499 12.675 3.97499 14.55C5.24999 15.75 7.05 16.5 9 16.5Z'
                          fill='currentColor'
                        />
                        <rect x={7} y={6} width={4} height={4} rx={2} fill='currentColor' />
                      </svg>
                    </span>
                    Operator PE
                  </a>
                  <a
                    href='javascript:void(0)'
                    className='d-flex align-items-center text-gray-400 text-hover-primary mb-2'
                  >
                    <span className='svg-icon svg-icon-4 me-1'>
                      <svg
                        width={24}
                        height={24}
                        viewBox='0 0 24 24'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          opacity='0.3'
                          d='M21 19H3C2.4 19 2 18.6 2 18V6C2 5.4 2.4 5 3 5H21C21.6 5 22 5.4 22 6V18C22 18.6 21.6 19 21 19Z'
                          fill='currentColor'
                        />
                        <path
                          d='M21 5H2.99999C2.69999 5 2.49999 5.10005 2.29999 5.30005L11.2 13.3C11.7 13.7 12.4 13.7 12.8 13.3L21.7 5.30005C21.5 5.10005 21.3 5 21 5Z'
                          fill='currentColor'
                        />
                      </svg>
                    </span>
                    {data?.email}
                  </a>
                </div>
              </div>
            </div>
            <div className='d-flex flex-wrap flex-stack'>
              <div className='d-flex flex-column flex-grow-1 pe-8'>
                <div className='d-flex flex-wrap'>
                  <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-3 me-3 mb-3'>
                    <div className='d-flex align-items-center'>
                      <KTIcon iconName='arrow-up' className='text-success fs-2 me-1' />
                      <div
                        className='fs-2 fw-bold'
                        data-kt-countup='true'
                        data-kt-countup-value={4500}
                        data-kt-countup-prefix='$'
                      >
                        {data?.url_count}
                      </div>
                    </div>
                    <div className='fw-semibold fs-6 text-gray-400'>URL registred</div>
                  </div>
                  {/* <div className='border border-gray-300 border-dashed rounded min-w-125px py-3 px-3 me-3 mb-3 bg-light-success'>
                    <div className='fw-semibold fs-6 text-gray-500'>Your Active Plan</div>
                    <div className='d-flex align-items-center'>
                      {data.user_subscriptions?.[0] ? (
                        <div className='fs-2 fw-bold text-success'>
                          {data.user_subscriptions[0].name}
                        </div>
                      ) : (
                        <>No Active plan</>
                      )}
                    </div>
                  </div> */}
                </div>
              </div>
              {/* <div className='d-flex align-items-center w-200px w-sm-300px flex-column mt-3'>
                <div className='d-flex justify-content-between w-100 mt-auto mb-2'>
                  <span className='fw-bold fs-6'>
                    SMS {usedsms} of {totalsms} used
                  </span>
                  <span className='fw-bold fs-6'>
                    {totalsms ? `${((usedsms / totalsms) * 100).toFixed(2)} %` : '0 %'}
                  </span>
                </div>
                <div className='h-5px mx-3 w-100 bg-light mb-3'>
                  <div
                    className='bg-success rounded h-5px'
                    role='progressbar'
                    style={{width: `${(usedsms / totalsms) * 100}%`}}
                  />
                </div>
              </div> */}
            </div>
          </div>
        </div>
        <ul className='nav nav-stretch nav-line-tabs nav-line-tabs-2x border-transparent fs-5 fw-bold'>
          <li className='nav-item mt-2'>
            <a
              className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                tab == MyProfileTabs.overview && 'active'
              }`}
              href='javacript:void(0)'
              onClick={() => setTab(MyProfileTabs.overview)}
            >
              Overview
            </a>
          </li>
          <li className='nav-item mt-2'>
            <a
              className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                tab == MyProfileTabs.settings && 'active'
              }`}
              href='javacript:void(0)'
              onClick={() => setTab(MyProfileTabs.settings)}
            >
              Settings
            </a>
          </li>
          {/* <li className='nav-item mt-2'>
            <a
              className={`nav-link text-active-primary ms-0 me-10 py-5 ${
                tab == MyProfileTabs.billing && 'active'
              }`}
              href='javacript:void(0)'
              onClick={() => setTab(MyProfileTabs.billing)}
            >
              Billing
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default EngageWidgetMyProfile
