import {useEffect, useState} from 'react'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {CardsWidget171} from '../../../_metronic/partials/widgets/_new/cards/CardsWidget171'
import {KTIcon} from '../../../_metronic/helpers'
import {axiosClientAuth} from '../../utils'
import moment from 'moment'

const urlRegistrationBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const getClassNameByStatus = (status: any) => {
  return status != null ? (Boolean(status) ? 'danger' : 'success') : 'warning'
}

const UrlRegistration = () => {
  const [urlData, setUrlData] = useState({
    urls_count: {
      totol: 0,
      pending: 0,
      whitelisted_by_operator: 0,
      blacklisted_by_operator: 0,
    },
    urls: [],
  })
  const [filteredData, setFilteredData] = useState({
    urls_count: {
      totol: 0,
      pending: 0,
      whitelisted_by_operator: 0,
      blacklisted_by_operator: 0,
    },
    urls: [],
  })
  const {setRightTolbar, urlDateFilter} = useLayout()

  useEffect(() => {
    setRightTolbar('urlregistration')
  }, [])

  useEffect(() => {
    axiosClientAuth
      .get('/pe/urls')
      .then((resp) => setUrlData(resp.data?.data))
      .catch((error) => console.log(error))
  }, [])

  useEffect(() => {
    if (urlDateFilter.length == 0 || (urlDateFilter[0] == '' && urlDateFilter[1] == '')) {
      setFilteredData(urlData)
    } else {
      const temp: any = urlData?.urls?.filter((url: any) => {
        const routeDate = moment(new Date(url?.created_at)).format('YYYY-MM-DD')
        const startDate = moment(new Date(urlDateFilter[0])).format('YYYY-MM-DD')
        const endDate = moment(new Date(urlDateFilter[1])).format('YYYY-MM-DD')

        return routeDate >= startDate && routeDate <= endDate
      })
      const temp_count: any = {
        total: temp.length,
        pending: 0,
        whitelisted_by_operator: 0,
        blacklisted_by_operator: 0,
      }

      for (const item of temp) {
        if (item.status === null) {
          temp_count.pending++
        } else if (item.status === true) {
          temp_count.whitelisted_by_operator++
        } else {
          temp_count.blacklisted_by_operator++
        }
      }

      setFilteredData({urls_count: temp_count, urls: temp})
    }
  }, [urlDateFilter, urlData])

  return (
    <>
      <PageTitle breadcrumbs={urlRegistrationBreadCrumbs}>My URLs</PageTitle>

      <div className={`card card-flush g-5 g-xl-10 mb-5 mb-xl-10 bg-primary`}>
        <div className='card-body'>
          <div className='row align-items-center'>
            <div className='col-md-6 col-lg-6 col-xl'>
              <CardsWidget171
                className='h-lg-100 h-100 border-0 bg-transparent'
                mLables={{
                  Whitelisted: filteredData.urls_count?.whitelisted_by_operator,
                  Blacklisted: filteredData.urls_count?.blacklisted_by_operator,
                  Pending: filteredData.urls_count?.pending,
                }}
                mColors={{
                  Whitelisted: 'bg-success',
                  Blacklisted: 'bg-danger',
                  Pending: 'bg-warning',
                }}
                heading='URL Summary'
                subHeading='URLs'
              />
            </div>
            <div className='col-sm-6 d-flex justify-content-center'>
              <img
                src='/media/svg/illustrations/easy/7.svg'
                className='h-200px h-lg-250px my-n6'
                alt=''
              />
            </div>
          </div>
        </div>
      </div>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {filteredData?.urls?.length == 0 && (
          <div className='d-flex justify-content-center flex-column align-items-center'>
            <img
              src='/media/illustrations/sketchy-1/5-dark.png'
              className='h-300px h-lg-350px my-n6'
              alt=''
            />
            <div className='mb-13 text-center'>
              <h1 className='mb-3'>No Data Found !!!</h1>
              <div className='text-muted fw-semibold fs-5'>
                If you need more info, please check{' '}
                <a href='#' className='fw-bold link-primary'>
                  Our Guidelines
                </a>
                .
              </div>
            </div>
          </div>
        )}
        {filteredData.urls?.map((item: any) => (
          <div className='col-md-4'>
            <div className={`card card-flush px-8 py-5 position-relative overflow-hidden`}>
              {item?.is_trusted && (
                <span className='activated-tag bg-success text-success fw-bold border-success bg-opacity-25'>
                  Trusted
                </span>
              )}
              <div className='d-flex flex-stack mb-3'>
                <div
                  className={`badge badge-light-${getClassNameByStatus(
                    item.is_blacklisted_by_operator
                  )}`}
                >
                  {item.is_blacklisted_by_operator != null
                    ? Boolean(item.is_blacklisted_by_operator)
                      ? 'Blacklisted'
                      : 'Whitelisted'
                    : 'Pending'}
                </div>
              </div>
              <div
                className={`d-flex align-items-center bg-light-${getClassNameByStatus(
                  item.is_blacklisted_by_operator
                )} rounded p-5 mb-7`}
              >
                <span className=' text-success me-5'>
                  <KTIcon
                    iconName='abstract-26'
                    className={`text-${getClassNameByStatus(
                      item.is_blacklisted_by_operator
                    )} fs-1 me-5`}
                  />
                </span>
                <div className='flex-grow-1'>
                  <a
                    href='javascript:void(0)'
                    className='fw-bold text-gray-600 text-hover-primary fs-6'
                  >
                    {item.name}
                  </a>
                  <span
                    className='text-white-800 fw-semibold d-block'
                    style={{wordBreak: 'break-word'}}
                  >
                    {item.url}
                  </span>
                  <span className='text-muted fw-semibold d-block'>
                    {moment(item.created_at).format('DD MMM YYYY LT')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </>
  )
}

export default UrlRegistration
