import {MyProfileTabs} from '../../../pages/myProfile/MyProfile'

const OverviewMyProfile = ({data, setTab}) => {
  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div className='card-header cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
          <a
            href='javascript:void(0)'
            className='btn btn-sm btn-primary align-self-center'
            onClick={() => setTab(MyProfileTabs.settings)}
          >
            Edit Profile
          </a>
        </div>
        <div className='card-body p-9'>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Full Name</label>
            <div className='col-lg-8'>
              <span className='fw-bold fs-6 text-gray-800'>{data?.name}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Principle Entity ID</label>
            <div className='col-lg-8 fv-row'>
              <span className='fw-semibold text-gray-800 fs-6'>{data?.pe_id}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Email</label>
            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.email}</span>
            </div>
          </div>
          <div className='row mb-7'>
            <label className='col-lg-4 fw-semibold text-muted'>Phone</label>
            <div className='col-lg-8 d-flex align-items-center'>
              <span className='fw-bold fs-6 text-gray-800 me-2'>{data?.phone}</span>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

export default OverviewMyProfile
