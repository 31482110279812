import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_metronic/layout/MasterLayout'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import UrlRegistration from '../pages/urlRegistration/UrlRegistration'
import UrlMapping from '../pages/urlMapping/UrlMapping'
import ManageRoutes from '../pages/manageRoutes/ManageRoutes'
import {SupportCenterWrapper} from '../pages/supportCenter/Support'
import {MyProfileWrapper} from '../pages/myProfile/MyProfile'
import VendorList from '../pages/vendorList/VendorList'
import MyIpWrapper from '../pages/myIp/MyIp'

const PrivateRoutes = () => {
  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='vendorlist' element={<VendorList />} />
        <Route path='urlregistration' element={<UrlRegistration />} />
        <Route path='urlmapping' element={<UrlMapping />} />
        <Route path='manageroutes' element={<ManageRoutes />} />
        <Route path='support' element={<SupportCenterWrapper />} />
        <Route path='myprofile' element={<MyProfileWrapper />} />
        <Route path='myip' element={<MyIpWrapper />} />

        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

export {PrivateRoutes}
