import React, {useEffect, useState} from 'react'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {KTIcon} from '../../../_metronic/helpers'
import PopupURLMapping from '../../../_metronic/partials/widgets/_new/popup/PopupURLMapping'
import {axiosClientAuth} from '../../utils'
import moment from 'moment'
import {Dropdown} from 'react-bootstrap'
import clsx from 'clsx'

const urlRegistrationBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const UrlMapping = () => {
  const {setRightTolbar, mappingDateFilter} = useLayout()
  const [data, setData] = useState([])
  const [filteredData, setFilteredData] = useState([])
  const [done, setDone] = useState(false)

  useEffect(() => {
    setRightTolbar('urlmapping')
  }, [])

  useEffect(() => {
    axiosClientAuth
      .get('/pe/url-mapping')
      .then((resp) => {
        console.log(resp)
        setData(resp.data?.data)
        setDone(true)
      })
      .catch((error) => {
        console.log(error)
        setDone(true)
      })
  }, [])

  useEffect(() => {
    if (
      mappingDateFilter.length == 0 ||
      (mappingDateFilter[0] == '' && mappingDateFilter[1] == '')
    ) {
      setFilteredData(data)
    } else {
      const temp: any = data?.filter((mapping: any) => {
        const mappingDate = moment(new Date(mapping?.created_at)).format('YYYY-MM-DD')
        const startDate = moment(new Date(mappingDateFilter[0])).format('YYYY-MM-DD')
        const endDate = moment(new Date(mappingDateFilter[1])).format('YYYY-MM-DD')

        return mappingDate >= startDate && mappingDate <= endDate
      })

      setFilteredData(temp)
    }
  }, [mappingDateFilter, data])

  return (
    <>
      <PageTitle breadcrumbs={urlRegistrationBreadCrumbs}>URL Mapping</PageTitle>

      <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
        {filteredData?.map((item: any) => (
          <div key={item.uuid} className='col-md-4'>
            <div className={`card card-flush px-10 py-5`}>
              <div className='row mb-5'>
                <div className='col-6'>
                  <div className={`badge badge-light-${Boolean(item.status) ? 'success' : 'dark'}`}>
                    {Boolean(item.status) ? 'Enabled' : 'Disabled'}
                  </div>
                </div>
              </div>
              <div
                className={`d-flex align-items-center bg-light-${
                  Boolean(item.status) ? 'success' : 'dark'
                } rounded p-5 mb-7`}
              >
                <span className=' text-success me-5'>
                  <KTIcon iconName='message-text-2' className='text-success fs-1 me-5' />
                </span>
                <div className='flex-grow-1 me-2'>
                  <a href='#' className='fw-bold text-gray-600 text-hover-primary fs-6'>
                    {item?.header_details?.name}
                  </a>
                  <span className='text-white-800 fw-semibold d-block'>
                    {item.mapped_template_content}
                  </span>
                  <span
                    className='text-muted fw-semibold d-block'
                    style={{wordBreak: 'break-word'}}
                  >
                    {moment(item.created_at).format('DD MMM YYYY LT')}
                  </span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      {done && filteredData.length <= 0 && (
        <div className='d-flex justify-content-center flex-column align-items-center'>
          <img
            src='/media/illustrations/sketchy-1/5-dark.png'
            className='h-300px h-lg-350px my-n6'
            alt=''
          />
          <div className='mb-13 text-center'>
            <h1 className='mb-3'>No Data Found !!!</h1>
            <div className='text-muted fw-semibold fs-5'>
              If you need more info, please check{' '}
              <a href='#' className='fw-bold link-primary'>
                Our Guidelines
              </a>
              .
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default UrlMapping
