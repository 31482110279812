import {SidebarMenuItem} from './SidebarMenuItem'

const SidebarMenuMain = () => {
  return (
    <>
      <SidebarMenuItem to='/dashboard' icon='element-11' title='Dashboard' />
      <SidebarMenuItem to='/urlregistration' icon='questionnaire-tablet' title='My URLs' />
      <SidebarMenuItem to='/urlmapping' icon='fasten' title='URL Mapping' />
      <SidebarMenuItem to='/myip' icon='files-tablet' title='My IP' />
      <SidebarMenuItem to='/vendorlist' icon='element-9' title='Vendor List' />
      <SidebarMenuItem to='/manageroutes' icon='route' title='Manage Routes' />
    </>
  )
}

export {SidebarMenuMain}
