import React, { useEffect } from 'react'
import { useSearchParams } from 'react-router-dom'
import { axiosClient } from '../utils'
import { useAuth } from '../modules/auth'

export default function LoginWithToken() {
  const [searchParams] = useSearchParams()
  const { saveAuth, setCurrentUser, logout } = useAuth()

  useEffect(() => {
    if (searchParams.get('token')) {
      logout();
      axiosClient.get("/auth/user-profile", { headers: { Authorization: 'Bearer ' + searchParams.get('token') } }).then(resp => {
        console.log(resp.data);
        const mData = {
          access_token: searchParams.get('token') || '',
          refresh: '',
          user: resp.data.data
        }
        saveAuth(mData)
        setCurrentUser(resp.data.data);
        window.location.href = "/dashboard";
      }).catch(err => {
        window.location.href = "/";
      });
    }
  }, [])

  return (
    <div>LoginWithToken</div>
  )
}
