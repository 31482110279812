import {FC, useEffect, useState} from 'react'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import EngageWidgetMyProfile from '../../../_metronic/partials/widgets/_new/engage/EngageWidgetMyProfile'
import OverviewMyProfile from '../../modules/apps/myprofile/OverviewMyProfile'
import MyProfileSettings from '../../modules/apps/myprofile/MyProfileSettings'
import {axiosClientAuth} from '../../utils'

export enum MyProfileTabs {
  'overview' = 'overview',
  'settings' = 'settings',
  'billing' = 'billing',
}

const MyProfile: FC = () => {
  const [tab, setTab] = useState(MyProfileTabs.overview)
  const [data, setData] = useState<any>({})
  const [preferences, setPreferences] = useState<any>()
  let [tabComponent, setTabComponent] = useState(<></>)

  const getData = () => {
    axiosClientAuth
      .get('/auth/user-profile')
      .then((resp) => setData(resp.data?.data))
      .catch((err) => console.log(err))

    axiosClientAuth
      .get('/common/notification-preference')
      .then((resp) => setPreferences(resp.data?.data))
      .catch((err) => console.log(err))
  }

  useEffect(() => {
    getData()
  }, [])

  useEffect(() => {
    if (data?.uuid) {
      switch (tab) {
        case MyProfileTabs.overview:
          setTabComponent(<OverviewMyProfile setTab={setTab} data={data} />)
          break
        case MyProfileTabs.settings:
          setTabComponent(<MyProfileSettings data={data} preferences={preferences} />)
          break
      }
    }
  }, [tab, data])

  return (
    <>
      <EngageWidgetMyProfile tab={tab} data={data} setTab={setTab} />
      {tabComponent}
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const MyProfileWrapper: FC = () => {
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('myprofile')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>My Profile</PageTitle>
      <MyProfile />
    </>
  )
}

export {MyProfileWrapper}
