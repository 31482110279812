/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTIcon} from '../../../../helpers'

type Props = {
  className: string
  data?: any
}

const ListsWidgetHighlight = ({className, data}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <div className='d-flex align-items-center'>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{data?.total}</span>
        </div>
        <span className='text-gray-400 pt-1 fw-semibold fs-6'>Total URLs Registered</span>
      </div>
    </div>
    <div className='card-body pt-5'>
      <div className='d-flex flex-column content-justify-center flex-row-fluid'>
        <div className='d-flex fw-semibold align-items-center'>
          <div className='text-gray-500 flex-grow-1 me-4'>Whitelisted URLs</div>
          <KTIcon iconName='arrow-up-right' className='text-success fs-2 me-2' />
          <div className='fw-bolder text-gray-700 text-xxl-end'>
            {data?.whitelisted_by_operator}
          </div>
        </div>
        <div className='separator separator-dashed my-3'></div>
        <div className='d-flex fw-semibold align-items-center'>
          <div className='text-gray-500 flex-grow-1 me-4'>Blacklisted URLs</div>
          <KTIcon iconName='arrow-down-right' className='text-danger fs-2 me-2' />
          <div className='fw-bolder text-gray-700 text-xxl-end'>
            {data?.blacklisted_by_operator}
          </div>
        </div>
        <div className='separator separator-dashed my-3'></div>
        <div className='d-flex fw-semibold align-items-center'>
          <div className='text-gray-500 flex-grow-1 me-4'>Pending URLs</div>
          <KTIcon iconName='arrow-down-right' className='text-danger fs-2 me-2' />
          <div className='fw-bolder text-gray-700 text-xxl-end'>{data?.pending}</div>
        </div>
      </div>
    </div>
  </div>
)

export {ListsWidgetHighlight}
