import {useNavigate} from 'react-router-dom'
import {KTIcon} from '../../../helpers'

const SidebarFooter = () => {
  const navigate = useNavigate()

  return (
    <div className='app-sidebar-footer flex-column-auto pt-2 pb-6 px-6' id='kt_app_sidebar_footer'>
      <a
        className='btn btn-flex gap-2 flex-center btn-custom btn-primary overflow-hidden text-nowrap px-0 h-40px w-100'
        title='Ticket Support'
        onClick={() => navigate('/support')}
      >
        <KTIcon iconName='message-question' className='fs-2 m-0' />
        <span className='btn-label'>Ticket Support</span>
      </a>
    </div>
  )
}

export {SidebarFooter}
