import React, {useEffect, useState} from 'react'
import {CardsWidget171} from '../../../../_metronic/partials/widgets/_new/cards/CardsWidget171'
import {KTIcon} from '../../../../_metronic/helpers'
import {axiosClientAuth} from '../../../utils'

const OverviewTickets = () => {
  const [data, setData] = useState([])
  const [done, setDone] = useState(false)

  useEffect(() => {
    axiosClientAuth
      .get('/support/operator-ticket')
      .then((resp) => {
        setData(resp.data.data)
        setDone(true)
      })
      .catch(() => {
        setDone(true)
      })
  }, [])

  return (
    <div className='row g-5 g-xl-10 mb-5 mb-xl-10'>
      <div className='col-md-6 col-lg-6 col-xl-6'>
        {done && (
          <CardsWidget171
            className='h-lg-100 h-100'
            mLables={{
              Open: data?.filter((item: any) => item.status == 'open').length,
              Processing: data?.filter((item: any) => item.status == 'processing').length,
              Resolved: data?.filter((item: any) => item.status == 'resolved').length,
            }}
            mColors={{Resolved: 'bg-success', Open: 'bg-danger', Processing: 'bg-warning'}}
            heading='Recent Tickets'
            subHeading='Tickets'
          />
        )}
      </div>

      <div className='col-lg-6'>
        <div className={`card card-flush h-lg-100 h-100`}>
          <div className='card-header pt-5'>
            <div className='card-title d-flex flex-column'>
              <div className='d-flex align-items-center'>
                <span className='fs-1 fw-bold text-dark me-2 lh-1 ls-n2'>Recent Tickets</span>
              </div>
            </div>
          </div>

          <div className='card-body pt-2 pb-4 d-flex flex-wrap'>
            <div className='d-flex flex-column content-justify-center flex-row-fluid pe-11'>
              <div style={{maxHeight: '350px', overflow: 'scroll'}}>
                {data.map((item: any, index: number) => (
                  <div key={index} className='d-flex mb-10'>
                    <span className='svg-icon svg-icon-2x me-5 ms-n1 mt-2 svg-icon-success'>
                      <KTIcon iconName='archive-tick' className='text-success fs-1' />
                    </span>
                    <div className='d-flex flex-column'>
                      <div className='d-flex align-items-center mb-2'>
                        <span className='text-dark text-hover-primary fs-4 me-3 fw-semibold'>
                          {item.title}
                        </span>
                        {item.status == 'open' ? (
                          <span className='badge badge-danger my-1'>Open</span>
                        ) : item.status == 'resolved' ? (
                          <span className='badge badge-success my-1'>Resolved</span>
                        ) : (
                          <span className='badge badge-warning my-1'>Processing</span>
                        )}
                      </div>
                      <span className='text-muted fw-semibold fs-6'>{item.description}</span>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default OverviewTickets
