import React, {useState} from 'react'
import {KTIcon} from '../../../../_metronic/helpers'
import {axiosClientAuth} from '../../../utils'
import Swal from 'sweetalert2'

const ContactUs = () => {
  const [input, setInput] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  })

  const onChangeHand = (e) => {
    setInput((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const submitHand = (e) => {
    e.preventDefault()

    axiosClientAuth
      .post('/common/contact-us', input)
      .then((res) =>
        Swal.fire({
          titleText: 'Success!',
          text: 'Form submitted Successfully. Team will contact you soon.',
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        }).then((isConfirm) => {
          if (isConfirm) {
            window.location.reload()
          }
        })
      )
      .catch((error) => {
        Swal.fire({
          titleText: 'Error!',
          text: `Please fill correct details`,
          icon: 'error',
          confirmButtonText: 'Ok! Got It',
          timer: 2000,
          timerProgressBar: true,
        })
      })
  }

  return (
    <div className='card'>
      <div className='card-body p-lg-17'>
        <div className='row mb-3'>
          <div className='col-md-6 pe-lg-10'>
            <form className='form mb-15' id='kt_contact_form' onSubmit={submitHand}>
              <h1 className='fw-bold text-dark mb-9'>Send Us Email</h1>
              <div className='row mb-5'>
                <div className='col-md-6 fv-row'>
                  <label className='fs-5 fw-semibold mb-2'>Name</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='name'
                    value={input.name}
                    onChange={onChangeHand}
                  />
                </div>
                <div className='col-md-6 fv-row'>
                  <label className='fs-5 fw-semibold mb-2'>Email</label>
                  <input
                    type='text'
                    className='form-control form-control-solid'
                    placeholder=''
                    name='email'
                    value={input.email}
                    onChange={onChangeHand}
                  />
                </div>
              </div>
              <div className='d-flex flex-column mb-5 fv-row'>
                <label className='fs-5 fw-semibold mb-2'>Subject</label>
                <input
                  className='form-control form-control-solid'
                  placeholder=''
                  name='subject'
                  value={input.subject}
                  onChange={onChangeHand}
                />
              </div>
              <div className='d-flex flex-column mb-10 fv-row'>
                <label className='fs-6 fw-semibold mb-2'>Message</label>
                <textarea
                  className='form-control form-control-solid'
                  rows={6}
                  name='message'
                  placeholder=''
                  value={input.message}
                  onChange={onChangeHand}
                />
              </div>
              <button type='submit' className='btn btn-primary' id='kt_contact_submit_button'>
                <span className='indicator-label'>Send Feedback</span>
                <span className='indicator-progress'>
                  Please wait...
                  <span className='spinner-border spinner-border-sm align-middle ms-2' />
                </span>
              </button>
            </form>
          </div>
          <div className='col-sm-6 pe-lg-10'>
            <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10 mb-10'>
              <h1 className='text-dark fw-bold my-5'>Let’s Speak</h1>
              <div className='text-gray-700 fw-semibold fs-2'>1 (833) 597-7538</div>
            </div>
            <div className='text-center bg-light card-rounded d-flex flex-column justify-content-center p-10'>
              <span>
                <i className='ki-duotone ki-geolocation fs-4x text-primary'>
                  <i className='path1'></i>
                  <i className='path2'></i>
                </i>
              </span>
              <h1 className='text-dark fw-bold my-5'>TCIL Head Office</h1>
              <div className='text-gray-700 fs-3 fw-semibold'>
                TCIL Bhawan, GK-I,
                <br />
                New Delhi-110048
              </div>
            </div>
          </div>
          <div className='col-sm-6 ps-lg-10'></div>
        </div>
        <div className='row g-5 mb-5 mb-lg-15'></div>
      </div>
    </div>
  )
}

export default ContactUs
