/* eslint-disable jsx-a11y/anchor-is-valid */
import {Fragment} from 'react'
import {KTIcon} from '../../../../helpers'

type Props = {
  className: string
  data?: any
}

const rows: Array<{description: string}> = [
  {description: 'Whitelisted IP'},
  {description: 'Blacklisted IP'},
]

const ListsWidgetOperator = ({className, data}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div className='card-header pt-5'>
      <div className='card-title d-flex flex-column'>
        <div className='d-flex align-items-center'>
          <span className='fs-2hx fw-bold text-dark me-2 lh-1 ls-n2'>{data?.vendor_count}</span>
        </div>
        <span className='text-gray-400 pt-1 fw-semibold fs-6'>Vendor Registered</span>
      </div>
    </div>
    <div className='card-body pt-5 overflow-hidden' style={{maxHeight: '145px'}}>
      {rows.map((row, index) => (
        <Fragment key={`lw26-rows-${index}`}>
          <div className='d-flex flex-stack'>
            <span className='text-gray-500 fw-semibold fs-6 me-2'>{row.description}</span>
            <div className='d-flex'>
              {index == 0 ? (
                <span className='svg-icon svg-icon-2 svg-icon-success me-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='16.9497'
                      y='8.46448'
                      width='13'
                      height='2'
                      rx='1'
                      transform='rotate(135 16.9497 8.46448)'
                      fill='currentColor'
                    />
                    <path
                      d='M14.8284 9.97157L14.8284 15.8891C14.8284 16.4749 15.3033 16.9497 15.8891 16.9497C16.4749 16.9497 16.9497 16.4749 16.9497 15.8891L16.9497 8.05025C16.9497 7.49797 16.502 7.05025 15.9497 7.05025L8.11091 7.05025C7.52512 7.05025 7.05025 7.52513 7.05025 8.11091C7.05025 8.6967 7.52512 9.17157 8.11091 9.17157L14.0284 9.17157C14.4703 9.17157 14.8284 9.52975 14.8284 9.97157Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              ) : index == 1 ? (
                <span className='svg-icon svg-icon-2 svg-icon-danger me-2'>
                  <svg
                    width='24'
                    height='24'
                    viewBox='0 0 24 24'
                    fill='none'
                    xmlns='http://www.w3.org/2000/svg'
                  >
                    <rect
                      opacity='0.5'
                      x='7.05026'
                      y='15.5355'
                      width='13'
                      height='2'
                      rx='1'
                      transform='rotate(-45 7.05026 15.5355)'
                      fill='currentColor'
                    />
                    <path
                      d='M9.17158 14.0284L9.17158 8.11091C9.17158 7.52513 8.6967 7.05025 8.11092 7.05025C7.52513 7.05025 7.05026 7.52512 7.05026 8.11091L7.05026 15.9497C7.05026 16.502 7.49797 16.9497 8.05026 16.9497L15.8891 16.9497C16.4749 16.9497 16.9498 16.4749 16.9498 15.8891C16.9498 15.3033 16.4749 14.8284 15.8891 14.8284L9.97158 14.8284C9.52975 14.8284 9.17158 14.4703 9.17158 14.0284Z'
                      fill='currentColor'
                    />
                  </svg>
                </span>
              ) : (
                ''
              )}
              <div className='fw-bolder text-gray-700 text-xxl-end'>
                {index == 0
                  ? data?.ipaddresses_count?.whitelisted_by_operator
                  : data?.ipaddresses_count?.blacklisted_by_operator}
              </div>
            </div>
          </div>
          {rows.length - 1 > index && <div className='separator separator-dashed my-3' />}
        </Fragment>
      ))}
    </div>
  </div>
)
export {ListsWidgetOperator}
