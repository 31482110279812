import {FC, createContext, useContext, useState, useEffect} from 'react'
import {DefaultConfig} from './_LayoutConfig'
import {
  setLayoutIntoLocalStorage,
  getEmptyCssClasses,
  getEmptyCSSVariables,
  getEmptyHTMLAttributes,
  LayoutSetup,
} from './_LayoutSetup'
import {
  ILayout,
  ILayoutCSSVariables,
  ILayoutCSSClasses,
  ILayoutHTMLAttributes,
  LayoutType,
  ToolbarType,
} from './_Models'
import {WithChildren} from '../../helpers'
import {AnySchema} from 'yup'

export interface LayoutContextModel {
  config: ILayout
  classes: ILayoutCSSClasses
  attributes: ILayoutHTMLAttributes
  cssVariables: ILayoutCSSVariables
  setLayout: (config: LayoutSetup) => void
  setLayoutType: (layoutType: LayoutType) => void
  setToolbarType: (toolbarType: ToolbarType) => void
  rightToolbar?: any
  setRightTolbar?: any
  currentTicket?: any
  setCurrentTicket?: any
  urlDateFilter?: any
  setUrlDateFilter?: any
  uuidOfVendorForIpList?: any
  setUuidOfVendorForIpList?: any
  vendorListDateFilter?: any
  setVendorListDateFilter?: any
  routeDateFilter?: any
  setRouteDateFilter?: any
  mappingDateFilter?: any
  setMappingDateFilter?: any
}

const LayoutContext = createContext<LayoutContextModel>({
  config: DefaultConfig,
  classes: getEmptyCssClasses(),
  attributes: getEmptyHTMLAttributes(),
  cssVariables: getEmptyCSSVariables(),
  setLayout: (config: LayoutSetup) => {},
  setLayoutType: (layoutType: LayoutType) => {},
  setToolbarType: (toolbarType: ToolbarType) => {},
  rightToolbar: '',
  setRightTolbar: () => {},
  currentTicket: '',
  setCurrentTicket: () => {},
  urlDateFilter: '',
  setUrlDateFilter: () => {},
  uuidOfVendorForIpList: '',
  setUuidOfVendorForIpList: () => {},
  vendorListDateFilter: '',
  setVendorListDateFilter: () => {},
  routeDateFilter: '',
  setRouteDateFilter: () => {},
  mappingDateFilter: '',
  setMappingDateFilter: () => {},
})

const enableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'flex')
  }
}

const disableSplashScreen = () => {
  const splashScreen = document.getElementById('splash-screen')
  if (splashScreen) {
    splashScreen.style.setProperty('display', 'none')
  }
}

const LayoutProvider: FC<WithChildren> = ({children}) => {
  const [config, setConfig] = useState(LayoutSetup.config)
  const [classes, setClasses] = useState(LayoutSetup.classes)
  const [attributes, setAttributes] = useState(LayoutSetup.attributes)
  const [cssVariables, setCSSVariables] = useState(LayoutSetup.cssVariables)
  const [rightToolbar, setRightTolbar] = useState(<></>)
  const [currentTicket, setCurrentTicket] = useState({})
  const [urlDateFilter, setUrlDateFilter] = useState('')
  const [uuidOfVendorForIpList, setUuidOfVendorForIpList] = useState('')
  const [vendorListDateFilter, setVendorListDateFilter] = useState('')
  const [routeDateFilter, setRouteDateFilter] = useState('')
  const [mappingDateFilter, setMappingDateFilter] = useState('')

  const setLayout = (_themeConfig: Partial<ILayout>) => {
    enableSplashScreen()
    const bodyClasses = Array.from(document.body.classList)
    bodyClasses.forEach((cl) => document.body.classList.remove(cl))
    const updatedConfig = LayoutSetup.updatePartialConfig(_themeConfig)
    setConfig(Object.assign({}, updatedConfig))
    setClasses(LayoutSetup.classes)
    setAttributes(LayoutSetup.attributes)
    setCSSVariables(LayoutSetup.cssVariables)
    setTimeout(() => {
      disableSplashScreen()
    }, 500)
  }

  const setToolbarType = (toolbarType: ToolbarType) => {
    const updatedConfig = {...config}
    if (updatedConfig.app?.toolbar) {
      updatedConfig.app.toolbar.layout = toolbarType
    }

    setLayoutIntoLocalStorage(updatedConfig)
    window.location.reload()
  }

  const setLayoutType = (layoutType: LayoutType) => {
    const updatedLayout = {...config, layoutType}
    setLayoutIntoLocalStorage(updatedLayout)
    window.location.reload()
  }

  const value: LayoutContextModel = {
    config,
    classes,
    attributes,
    cssVariables,
    setLayout,
    setLayoutType,
    setToolbarType,
    rightToolbar,
    setRightTolbar,
    currentTicket,
    setCurrentTicket,
    urlDateFilter,
    setUrlDateFilter,
    uuidOfVendorForIpList,
    setUuidOfVendorForIpList,
    vendorListDateFilter,
    setVendorListDateFilter,
    routeDateFilter,
    setRouteDateFilter,
    mappingDateFilter,
    setMappingDateFilter,
  }

  useEffect(() => {
    disableSplashScreen()
  }, [])

  return <LayoutContext.Provider value={value}>{children}</LayoutContext.Provider>
}

export {LayoutContext, LayoutProvider}

export function useLayout() {
  return useContext(LayoutContext)
}
