import {useState} from 'react'
import {axiosClientAuth} from '../../../utils'
import Swal from 'sweetalert2'

const MyProfileSettings = ({data, preferences}) => {
  const [input, setInput] = useState({
    name: data?.name || '',
    phone: data?.phone || '',
    pe_id: data?.pe_id,
  })
  const [preferenceCheck, setPreferenceCheck] = useState(preferences)

  const onSubmitFormProfile = (e) => {
    e.preventDefault()

    axiosClientAuth
      .patch('/auth/user-profile', input)
      .then(() => {
        Swal.fire({
          titleText: 'Success!',
          text: 'Profile Details Updated Successfully.',
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        }).then((isConfirm) => {
          if (isConfirm) {
            window.location.reload()
          }
        })
      })
      .catch(() => {
        Swal.fire({
          titleText: 'Error!',
          text: 'Internal Server Issue!!!',
          icon: 'error',
          confirmButtonText: 'Ok! Got It',
          timer: 2000,
          timerProgressBar: true,
        })
      })
  }

  const onChangeProfileHand = (e) => {
    e.preventDefault()

    setInput((prev) => ({...prev, [e.target.name]: e.target.value}))
  }

  const notiPrefSubmit = (e) => {
    e.preventDefault()

    const mData = preferenceCheck?.map((ele) => ({
      notification_preference: ele.uuid,
      is_email: ele.is_email,
      is_phone: ele.is_phone,
      is_notification: ele.is_notification,
    }))

    axiosClientAuth
      .patch('/auth/notification-preference', mData)
      .then((res) => {
        Swal.fire({
          titleText: 'Success!',
          text: 'Notification Preference Updated Successfully.',
          icon: 'success',
          confirmButtonText: 'Ok! Got It',
        }).then((isConfirm) => {
          if (isConfirm) {
            window.location.reload()
          }
        })
      })
      .catch((err) => {
        console.log(err)
      })
  }

  return (
    <>
      <div className='card mb-5 mb-xl-10'>
        <div
          className='card-header border-0 cursor-pointer'
          role='button'
          data-bs-toggle='collapse'
          data-bs-target='#kt_account_profile_details'
          aria-expanded='true'
          aria-controls='kt_account_profile_details'
        >
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Profile Details</h3>
          </div>
        </div>
        <div id='kt_account_settings_profile_details' className='collapse show'>
          <form onSubmit={onSubmitFormProfile} className='form'>
            <div className='card-body border-top p-9'>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label required fw-semibold fs-6'>
                  Full Name
                </label>
                <div className='col-lg-8'>
                  <input
                    type='text'
                    className='form-control form-control-lg form-control-solid mb-3 mb-lg-0'
                    name='name'
                    placeholder='Full name'
                    value={input.name}
                    onChange={onChangeProfileHand}
                  />
                </div>
              </div>
              <div className='row mb-6'>
                <label className='col-lg-4 col-form-label fw-semibold fs-6'>
                  <span className='required'>Contact Number</span>
                </label>
                <div className='col-lg-8 fv-row'>
                  <input
                    type='tel'
                    className='form-control form-control-lg form-control-solid'
                    name='phone'
                    placeholder='Contact number'
                    value={input.phone}
                    onChange={onChangeProfileHand}
                    maxLength={10}
                  />
                </div>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='reset' className='btn btn-light btn-active-light-primary me-2'>
                Discard
              </button>
              <button
                type='submit'
                className='btn btn-primary'
                id='kt_account_profile_details_submit'
              >
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>

      <div className='card mb-5 mb-xl-10'>
        <div className='card-header border-0 cursor-pointer'>
          <div className='card-title m-0'>
            <h3 className='fw-bold m-0'>Notifications Preferences</h3>
          </div>
        </div>
        <div>
          <form className='form' onSubmit={notiPrefSubmit}>
            <div className='card-body border-top px-9 pt-3 pb-4'>
              <div className='table-responsive'>
                <table className='table table-row-dashed border-gray-300 align-middle gy-6'>
                  <tbody className='fs-6 fw-semibold'>
                    {preferenceCheck?.map((item: any, index: number) => (
                      <tr key={index}>
                        <td className='min-w-250px'>
                          <span className='fw-bold fs-5 mb-0'>{item.name}</span>
                          <span className='d-block text-muted fs-6'>{item.description}</span>
                        </td>
                        <td className='w-150px'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='my_checkbox_notification'
                              checked={item.is_notification}
                              onChange={() => {
                                let tempPref = preferenceCheck
                                tempPref[index] = {
                                  ...tempPref[index],
                                  is_notification: !tempPref[index].is_notification,
                                }
                                setPreferenceCheck(JSON.parse(JSON.stringify(tempPref)))
                              }}
                            />
                            <label className='form-check-label ps-2' htmlFor='my_checkbox_email'>
                              Notification
                            </label>
                          </div>
                        </td>
                        <td className='w-125px'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='my_checkbox_email'
                              checked={item.is_email}
                              onChange={() => {
                                let tempPref = preferenceCheck
                                tempPref[index] = {
                                  ...tempPref[index],
                                  is_email: !tempPref[index].is_email,
                                }
                                setPreferenceCheck(JSON.parse(JSON.stringify(tempPref)))
                              }}
                            />
                            <label className='form-check-label ps-2' htmlFor='my_checkbox_email'>
                              Email
                            </label>
                          </div>
                        </td>
                        <td className='w-125px'>
                          <div className='form-check form-check-custom form-check-solid'>
                            <input
                              className='form-check-input'
                              type='checkbox'
                              id='my_checkbox_phone'
                              checked={item.is_phone}
                              onChange={() => {
                                let tempPref = preferenceCheck
                                tempPref[index] = {
                                  ...tempPref[index],
                                  is_phone: !tempPref[index].is_phone,
                                }
                                setPreferenceCheck(JSON.parse(JSON.stringify(tempPref)))
                              }}
                            />
                            <label className='form-check-label ps-2' htmlFor='my_checkbox_phone'>
                              Phone
                            </label>
                          </div>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
            <div className='card-footer d-flex justify-content-end py-6 px-9'>
              <button type='button' className='btn btn-light btn-active-light-primary me-2'>
                Discard
              </button>
              <button type='submit' className='btn btn-primary px-6'>
                Save Changes
              </button>
            </div>
          </form>
        </div>
      </div>
    </>
  )
}

export default MyProfileSettings
