import {DatePicker} from 'antd'
import {useLayout} from '../../../_metronic/layout/core'

function ManageRoutesRightToolbar() {
  const {RangePicker} = DatePicker
  const {setRouteDateFilter} = useLayout()

  return (
    <>
      <div className='d-flex justify-content-end'>
        <RangePicker
          className='bg-secondary border-0 text-white me-2'
          style={{height: '35px'}}
          onChange={(value, datestring) => setRouteDateFilter(datestring)}
        />
      </div>
    </>
  )
}

export default ManageRoutesRightToolbar
