/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import {KTIcon} from '../../../../helpers'
import {Dropdown1} from '../../../content/dropdown/Dropdown1'
import moment from 'moment'

type Props = {
  className: string
  data?: any
}

const urlList = [
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
  {
    url: 'https://shorturl.at/dfpKP',
    parent: 'FL_studio',
    date: '17 Aug 2023 10:29 AM',
  },
]

const ListsWidgetApprovedURL: React.FC<Props> = ({className, data}) => {
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Approved URLs</span>
          <span className='text-muted fw-semibold fs-7'>Total {data?.length} URLs Approved</span>
        </h3>
        <div className='card-toolbar'></div>
      </div>
      <div
        className='card-body pt-2 mb-5'
        style={{height: '376px', maxHeight: '376px', overflow: 'scroll'}}
      >
        {data?.map((item: any) => {
          return (
            <div className='d-flex align-items-center mb-8'>
              <span className='bullet bullet-vertical h-40px bg-success'></span>
              <div className='form-check form-check-custom form-check-solid mx-5'>
                <input className='form-check-input' type='checkbox' value='' checked />
              </div>
              <div className='flex-grow-1'>
                <a href='#' className='text-gray-800 text-hover-primary fw-bold fs-6'>
                  {item.url}
                </a>
                <span className='text-muted fw-semibold d-block'>
                  <a href='javascript:void(0)'>{item.name} </a>
                  {moment(item.created_at).format('DD MMM YYYY LT')}
                </span>
              </div>
              {/* <span className='badge badge-light-success fs-8 fw-bold'>New</span> */}
            </div>
          )
        })}
      </div>
      {/* end::Body */}
    </div>
  )
}

export {ListsWidgetApprovedURL}
