import React, {useState} from 'react'
import {KTSVG} from '../../../../helpers'
import {tabs} from '../../../../../app/pages/supportCenter/Support'
import ModalCreateTicket from '../modal/ModalCreateTicket'

const EngageWidgetSupport = ({tab, setTab}) => {
  const [show, setShow] = useState(false)

  return (
    <div className='card mb-12'>
      <div className='card-body flex-column p-5'>
        {/* <div className='d-flex align-items-center h-lg-300px p-5 p-lg-15'>
          <div className='d-flex flex-column align-items-start justift-content-center flex-equal me-5'>
            <h1 className='fw-bold fs-4 fs-lg-1 text-gray-800 mb-5 mb-lg-10'>
              How Can We Help You?
            </h1>
            <div className='position-relative w-100'>
              <span className='svg-icon svg-icon-2 svg-icon-primary position-absolute top-50 translate-middle ms-8'>
                <KTSVG
                  path='/media/icons/duotune/general/gen021.svg'
                  className='svg-icon-primary'
                />
              </span>
              <input
                type='text'
                className='form-control fs-4 py-4 ps-14 text-gray-700 placeholder-gray-400 mw-500px'
                name='search'
                defaultValue=''
                placeholder='Ask a question'
              />
            </div>
          </div>
          <div className='flex-equal d-flex justify-content-center align-items-end ms-5'>
            <img
              src='/media/illustrations/sketchy-1/20.png'
              alt=''
              className='mw-100 mh-125px mh-lg-275px mb-lg-n12'
            />
          </div>
        </div> */}
        <div className='card-rounded bg-light d-flex flex-stack flex-wrap p-5'>
          <ul className='nav flex-wrap border-transparent fw-bold'>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                  tab == tabs.overview && 'active'
                }`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.overview)}
              >
                Overview
              </a>
            </li>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                  tab == tabs.tickets && 'active'
                }`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.tickets)}
              >
                tickets
              </a>
            </li>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                  tab == tabs.faq && 'active'
                }`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.faq)}
              >
                FAQ
              </a>
            </li>
            <li className='nav-item my-1'>
              <a
                className={`btn btn-color-gray-600 btn-active-secondary btn-active-color-primary fw-bolder fs-8 fs-lg-base nav-link px-3 px-lg-8 mx-1 text-uppercase ${
                  tab == tabs.contactUs && 'active'
                }`}
                href='javascript:void(0)'
                onClick={() => setTab(tabs.contactUs)}
              >
                Contact US
              </a>
            </li>
          </ul>
          <a
            href='javascript:void(0)'
            className='btn btn-primary fw-bold fs-8 fs-lg-base'
            onClick={() => setShow(true)}
          >
            Create Ticket
          </a>
        </div>
      </div>
      <ModalCreateTicket show={show} setShow={setShow} />
    </div>
  )
}

export default EngageWidgetSupport
