import React, {useEffect, useState} from 'react'
import {KTIcon, KTSVG} from '../../../../_metronic/helpers'
import {ChatInner} from '../../../../_metronic/partials'
import {axiosClientAuth} from '../../../utils'
import {Card1} from '../../../../_metronic/partials/content/cards/Card1'

const Tickets = () => {
  const [data, setData] = useState([])

  useEffect(() => {
    axiosClientAuth
      .get('/support/operator-ticket')
      .then((resp) => setData(resp.data.data))
      .catch(() => {})
  }, [])

  return (
    <div>
      <div className='row g-6 g-xl-9'>
        {data?.map((item: any, index: number) => (
          <div className='col-md-4 col-xxl-4'>
            <Card1
              name={item.title}
              job={item.description}
              status={item.status}
              totalEarnings='$236,400'
              item={item}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Tickets
