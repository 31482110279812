import { DatePicker } from 'antd'
import { useLayout } from '../../../_metronic/layout/core'

export default function UrlRegistrationToolBar() {
  const { setUrlDateFilter } = useLayout();
  const { RangePicker } = DatePicker
  return (
    <div className='d-flex justify-content-end'>
      <RangePicker onChange={(value, datestring) => setUrlDateFilter(datestring)} className='bg-secondary border-0 text-white' style={{ height: '35px' }} />
    </div>
  )
}
