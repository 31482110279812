import {DatePicker} from 'antd'
import React from 'react'
import {useLayout} from '../../../_metronic/layout/core'

export default function UrlMappingRightToolbar() {
  const {RangePicker} = DatePicker
  const {setMappingDateFilter} = useLayout()

  return (
    <div className='d-flex justify-content-end'>
      <RangePicker
        className='bg-secondary border-0 text-white'
        style={{height: '35px'}}
        onChange={(value, datestring) => setMappingDateFilter(datestring)}
      />
    </div>
  )
}
