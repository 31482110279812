/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC, useEffect, useState} from 'react'
import {toAbsoluteUrl} from '../../../_metronic/helpers'
import {PageLink, PageTitle, useLayout} from '../../../_metronic/layout/core'
import {CardsWidget17, ListsWidgetHighlight} from '../../../_metronic/partials/widgets'
import {ChartsWidgetSMSStats} from '../../../_metronic/partials/widgets/_new/charts/ChartsWidgetSMSStats'
import {ListsWidgetApprovedURL} from '../../../_metronic/partials/widgets/_new/lists/ListsWidgetApprovedURL'
import {ListsWidgetOperator} from '../../../_metronic/partials/widgets/_new/lists/ListsWidgetOperator'
import {TablesWidgetScrubbingLogs} from '../../../_metronic/partials/widgets/_new/tables/TablesWidgetScrubbingLogs'
import {axiosClientAuth} from '../../utils'
import {CardsWidgetPlanDetails} from '../../../_metronic/partials/widgets/_new/cards/CardsWidgetPlanSummary'
import CardsDashboardMain from '../../../_metronic/partials/widgets/_new/cards/CardsDashboardMain'

const DashboardPage: FC = () => {
  const [data, setData] = useState()
  const [scrubbingLogs, setScrubbingLogs] = useState([])

  useEffect(() => {
    axiosClientAuth
      .get('/pe/dashboard')
      .then((res) => setData(res.data.data))
      .catch((error) => console.log(error))

    axiosClientAuth
      .get('/main/get-scrubbing-logs')
      .then((res) => setScrubbingLogs(res.data.data))
      .catch((err) => console.log(err))
  }, [])

  return (
    <>
      <div className='row g-5 g-xl-10'>
        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
          {/* <CardsWidgetPlanDetails
            className='h-100'
            description='Plan Details'
            plan={data?.['sms_pack']?.['sms_pack']}
            totalsms={data?.['sms_pack']?.['total'] || 0}
            usedsms={data?.['sms_pack']?.['used_sms_count'] || 0}
            color='#F1416C'
            img={toAbsoluteUrl('/media/patterns/vector-1.png')}
          /> */}
          <CardsDashboardMain data={data} className='h-100' />
        </div>
        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
          <CardsWidget17 className='h-md-100 mb-5 mb-xl-10' data={data} />
        </div>

        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
          <ListsWidgetOperator className='h-md-100 mb-5 mb-xl-10' data={data} />
        </div>

        <div className='col-md-3 col-lg-3 col-xl-3 col-xxl-3 mb-md-5 mb-xl-10'>
          <ListsWidgetHighlight className='h-lg-100' data={data?.['urls_count']} />
        </div>
      </div>

      <div className='row gx-5 gx-xl-10'>
        <div className='col-xxl-6'>
          <ChartsWidgetSMSStats className='card-xl-stretch mb-xl-8' />
        </div>

        <div className='col-xxl-6'>
          <ListsWidgetApprovedURL
            className='card-xl-stretch mb-5 mb-xl-8'
            data={data?.['approved_urls']}
          />
        </div>
      </div>

      <div className='row g-5 gx-xxl-8'>
        <div className='col-xxl-12'>
          <TablesWidgetScrubbingLogs
            scrubbingLogs={scrubbingLogs}
            className='card-xxl-stretch mb-5 mb-xl-10'
          />
        </div>
      </div>
    </>
  )
}

const myBreadCrumbs: Array<PageLink> = [
  {
    title: 'Home',
    path: '/dashboard',
    isSeparator: false,
    isActive: false,
  },
  {
    title: '',
    path: '',
    isSeparator: true,
    isActive: false,
  },
]

const DashboardWrapper: FC = () => {
  const {setRightTolbar} = useLayout()

  useEffect(() => {
    setRightTolbar('vendorlist')
  }, [])

  return (
    <>
      <PageTitle breadcrumbs={myBreadCrumbs}>Dashboard</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
